export default function Score(props){
	props = Object.assign({
		color: "white",
		width: '50',
		height: '50',
	}, props);
	return (<svg width={props.width} height={props.height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.5 43V10H29.5V43H23.5V22H11.5V43H7V4H4V43C4 43.7957 4.31607 44.5587 4.87868 45.1213C5.44129 45.6839 6.20435 46 7 46H46V43H41.5ZM20.5 43H14.5V25H20.5V43ZM38.5 43H32.5V13H38.5V43Z" fill={props.color}/>
<path d="M29.5 4H41.5V7H29.5V4Z" fill={props.color}/>
<path d="M11.5 16H23.5V19H11.5V16Z" fill={props.color}/>
</svg>
)
}

