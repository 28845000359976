import React from 'react'
import './MyMap.css'
import { 
	// arrayFactory, 
	utilLog 
} from './MyUtil.js';
import markerIcon from './images/Marker.svg';

class MyMap extends React.Component {
  log(...msg){
    msg.unshift('MyMap:')
    utilLog.apply(null, msg)
	}
	constructor(props){
		super(props);
		this.log('constructor')
		this.lat = this.props.lat
		this.lng = this.props.lng
		if(this.lat === null || this.lng === null){
			throw Error()
		}
		this.google = window.google;
		// this.points = arrayFactory({
		// 	addFunc: v => {
		// 		this.log(v)
		// 		v.marker = this.createMarker(v.data.lat, v.data.lng)
		// 		// v.marker.addListener('click', _ => {
		// 		// 	if(this.points.activeIndex === v.index){
		// 		// 		v.deactivate(true)
		// 		// 	}
		// 		// 	else{
		// 		// 		v.activate(true)
		// 		// 	}
		// 		// })
		// 		v.infoWindow  = this.createInfoWindow(v.data)
		// 		// v.infoWindow.
		// 		v.activate = _ => {
		// 			this.points.deactivateAll()
		// 			this.points.activeIndex = v.index
		// 			v.infoWindow.open(this.map, v.marker)
		// 		}
		// 		v.deactivate = _ => {
		// 			this.points.activeIndex = -1
		// 			v.infoWindow.close()
		// 		}
		// 	},
		// 	deactivateAll: function(){
		// 		this.log(this)
		// 		this.getAll().forEach(v => v.deactivate())
		// 	}
		// })
	}
	componentDidMount(){
		this.log('componentDidMount')
		this.map = new this.google.maps.Map(document.getElementById("my-map_map"),{
			center: {
				lat: this.lat,
				lng: this.lng
			},
			zoom: 17,
			disableDefaultUI: true,
			gestureHandling: "greedy",
			clickableIcons: false,
			styles: [{
				"featureType": "all",
				"elementType": "labels.icon",
				"stylers":[{
					"visibility": "off",
				}]
			}]
		})
	}
	componentDidUpdate(){
		this.log('componentDidUpdate')
	}
	render(){
		return(
			<div id="my-map_map"></div>
		);
	}
	createMarker(lat, lng, config){
		config = Object.assign({
			// position: {
			// 	lat: lat,
			// 	lng: lng,
			// },
			map: this.map,
			visible: true,
			// draggable: false,
			icon: markerIcon,
		}, config)
		if(!isNaN(lat) && !isNaN(lng)){
			config = Object.assign({
				position: {
					lat: lat,
					lng: lng,
				}
			}, config)
		}
		return new this.google.maps.Marker(config)
	}
	createInfoWindow(data){
		let infoWindow = new this.google.maps.InfoWindow({
			content: `<div class="my-info-window">
			<div>${data.name}</div>
			<div>${data.description}</div>
			</div>`
		})
		return infoWindow
	}
	componentWillUnmount(){
		this.log('componentWillUnmount')
		this.points.clear()
	}
}

export default MyMap;