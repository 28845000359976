import React from 'react';
import { 
	Modal
} from 'react-bootstrap'
import './TaskPanel.css';
import ChooseQuestion from './ChooseQuestion.js';
import TaskFragment from './TaskFragment.js';
import { utilLog } from './MyUtil';

class TaskPanel extends React.Component{
	log(...msg){
		msg.unshift('TaskPanel:')
		utilLog.apply(null, msg)
	}
	constructor(props){
		super(props)
	    this.log('constructor')
		this.state={
			show: this.props.show,
			targetTask: null,
			tasks: this.props.tasks
		}
		this.tasks = this.props.tasks
		this.show = this.props.show
		// if(){
		// 	this.setState({show: this.props.show})
		// }
	}
	// componentDidMount(){
	//     this.log('componentDidMount')
	//     this.this.props.tasks
	// }
	componentDidUpdate(){
		this.log('componentDidUpdate')
		this.log(this.props.tasks)
		let newDiff = this.props.tasks.length - this.tasks.length;
		let isSthNew = this.props.tasks.some(t => this.tasks.findIndex(t1=>t1.code === t.code) === -1)
		if(newDiff !== 0 || isSthNew){
			let isSthNotDone=this.props.tasks.some(t => !t.done)
			if(newDiff > 0 && isSthNotDone){
				// this.setState({targetTask: this.tasks[0]})
				this.showModal()
			}
			else if(this.props.tasks.length === 0){
				this.hideModal()
			}
			this.tasks = this.props.tasks.map(t=>t)
			this.setState({tasks: this.tasks})
		}
		this.log('this.props.show', this.props.show)
		this.log('this.props.show !== this.show', this.props.show !== this.show)
		// if(this.props.show !== this.show){
		// 	if(this.props.show && this.tasks.length > 0){
		// 		this.showModal()
		// 	}
		// 	this.show = this.props.show
		// }
		if(!this.state.show && this.props.show && this.tasks.length > 0){
			this.showModal()
		}
	}
	showModal(){
		if(this.props.allowShow){
			this.setState({show: true})
		}
		else{
			this.props.OnClose()
			this.props.OnNotify()
		}
	}
	hideModal(){
		this.setState({
			show: false,
			targetTask: null
		})
		if(this.props.show && this.props.OnClose){
			this.props.OnClose()
		}
	}

	// handleChangeTargetTask(e){
	// 	console.log(e)
	// 	console.log(e.target.value)
	// 	console.log()
	// 	let newTargetTask = this.props.tasks.find(t=>t.code == e.target.value)
	// 	this.setState({targetTask: newTargetTask})
	// }
	render(){
		// console.log(this.props)
		return(
			<Modal show={this.state.show} onHide={this.hideModal.bind(this)} backdrop="static">
				<Modal.Body>
					{
						this.state.targetTask === null &&
						this.state.tasks.length > 0 && 
						<ChooseQuestion 
							tasks={this.state.tasks} 
							onClickHide={this.hideModal.bind(this)} 
							OnConfirmChooseing={(task)=>this.setState({
								targetTask: task
							})} 
						/>
					}
					{
						this.state.targetTask !== null && 
						<TaskFragment 
							task={this.state.targetTask} 
							onBack={()=>this.setState({targetTask: null})}
							onUpdateStatus={(condi, updateFunc) => {
								this.setState(state => {
									let index = state.tasks.findIndex(condi)
									if(index > -1){
										state.tasks[index] = updateFunc(state.tasks[index])
									}
									return {tasks: state.tasks}
								})
							}}
						/>
					}
				</Modal.Body>
			</Modal>
		)
	}
}

export default TaskPanel;