export default function Timer(props){
	props = Object.assign({
		color: "white",
		width: '50',
		height: '50',
	}, props);
	return (<svg width={props.width} height={props.height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 5.5C16.5 5.10218 16.658 4.72064 16.9393 4.43934C17.2206 4.15804 17.6022 4 18 4H30C30.3978 4 30.7794 4.15804 31.0607 4.43934C31.342 4.72064 31.5 5.10218 31.5 5.5C31.5 5.89782 31.342 6.27936 31.0607 6.56066C30.7794 6.84196 30.3978 7 30 7H18C17.6022 7 17.2206 6.84196 16.9393 6.56066C16.658 6.27936 16.5 5.89782 16.5 5.5Z" fill={props.color}/>
<path d="M24 16C23.6022 16 23.2206 16.158 22.9393 16.4393C22.658 16.7206 22.5 17.1022 22.5 17.5V29.5C22.5 29.8978 22.658 30.2794 22.9393 30.5607C23.2206 30.842 23.6022 31 24 31C24.3978 31 24.7794 30.842 25.0607 30.5607C25.342 30.2794 25.5 29.8978 25.5 29.5V17.5C25.5 17.1022 25.342 16.7206 25.0607 16.4393C24.7794 16.158 24.3978 16 24 16Z" fill={props.color}/>
<path d="M42 28C42 32.7739 40.1036 37.3523 36.7279 40.7279C33.3523 44.1036 28.7739 46 24 46C19.2261 46 14.6477 44.1036 11.2721 40.7279C7.89642 37.3523 6 32.7739 6 28C6 23.2261 7.89642 18.6477 11.2721 15.2721C14.6477 11.8964 19.2261 10 24 10C28.7739 10 33.3523 11.8964 36.7279 15.2721C40.1036 18.6477 42 23.2261 42 28ZM39 28C39 24.0218 37.4196 20.2064 34.6066 17.3934C31.7936 14.5804 27.9782 13 24 13C20.0218 13 16.2064 14.5804 13.3934 17.3934C10.5804 20.2064 9 24.0218 9 28C9 31.9782 10.5804 35.7936 13.3934 38.6066C16.2064 41.4196 20.0218 43 24 43C27.9782 43 31.7936 41.4196 34.6066 38.6066C37.4196 35.7936 39 31.9782 39 28Z" fill={props.color}/>
<path d="M38.8587 8.92571C38.7203 8.78244 38.5548 8.66817 38.3718 8.58955C38.1888 8.51094 37.992 8.46956 37.7928 8.46783C37.5937 8.4661 37.3961 8.50405 37.2118 8.57947C37.0274 8.65489 36.86 8.76627 36.7191 8.90711C36.5783 9.04795 36.4669 9.21543 36.3915 9.39977C36.3161 9.58412 36.2781 9.78164 36.2798 9.98081C36.2816 10.18 36.323 10.3768 36.4016 10.5598C36.4802 10.7428 36.5945 10.9083 36.7377 11.0467L40.9467 15.2557C41.0851 15.399 41.2506 15.5132 41.4336 15.5919C41.6166 15.6705 41.8134 15.7119 42.0126 15.7136C42.2118 15.7153 42.4093 15.6774 42.5936 15.6019C42.778 15.5265 42.9455 15.4151 43.0863 15.2743C43.2271 15.1335 43.3385 14.966 43.4139 14.7816C43.4894 14.5973 43.5273 14.3998 43.5256 14.2006C43.5239 14.0014 43.4825 13.8046 43.4039 13.6216C43.3253 13.4386 43.211 13.2731 43.0677 13.1347L38.8587 8.92571Z" fill={props.color}/>
</svg>)
}

