// import logo from './logo.svg';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { 
  Spinner, 
  // Modal 
} from 'react-bootstrap'
import { Alert, AlertTitle } from '@material-ui/lab';
// import CloseIcon from '@material-ui/icons/Close';
import { 
  sendRequest, 
  utilLog, 
  AuthContext
} from './MyUtil.js';
import { Login } from './Login.js';
import StudentApp from './StudentApp.js';

class App extends React.Component{
  log(...msg){
    msg.unshift('App:')
    utilLog.apply(null, msg)
  }
  constructor(props){
    super(props)
    this.log('constructor')
    this.state = {
      status: {
        logined: null,
        name: '',
        score: 0,
        hv_error: false,
        error_msg: ''
      },
    }
    this.onLogin = (status) => {
      this.setState(state => ({
        status: Object.assign({}, state.status, status)
      }))
    }
    this.onError = (msg) => {
      this.setState({
        hv_error: true,
        error_msg: msg
      })
      setTimeout(_ => {
        this.setState({ hv_error: false })
      }, 3000)
    }
  }
  componentDidMount(){
    this.log('componentDidMount')
    sendRequest('student/trip/status', {
      autoShowLoader: false
    }).then(r=>{
      this.onLogin({
        logined: true,
        name: r.name,
        score: r.score
      })
    }).catch(e => {
      this.onLogin({
        logined: false,
        name: '',
        score: 0
      })
    })
  }
  render() {
    return (
      <AuthContext.Provider 
      value={{
        status: this.state.status, 
        onLogin: this.onLogin,
        onError: this.onError
      }}>
        {this.state.status.logined !== null &&
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route path="/login">
                {!this.state.status.logined ?
                  <Login /> :
                  <Redirect to="/trip" />
                }
              </Route>
              <Route path="/trip">
                {this.state.status.logined ?
                  <StudentApp /> :
                  <Redirect to="/login" />
                }
              </Route>
              <Route>
                <Redirect to="/login" />
              </Route>
            </Switch>
          </Router>
        }
        {this.state.status.logined === null &&
          <div style={{
            height: '100vh',
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Spinner animation="border" />
          </div>
        }
        {this.state.hv_error &&
          <div style={{
            width: '60vw',
            position: 'fixed',
            top: '60px',
            left: '20vw'
          }}>
            <Alert 
              severity="error" 
              onClose={_ => this.setState({ hv_error: false })}
            >
              <AlertTitle>Error</AlertTitle>
              {this.state.error_msg}
            </Alert>
          </div>
        }
      </AuthContext.Provider>
    );
  }
}

export default App;