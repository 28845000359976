export default function Route(props){
	props = Object.assign({
		color: "white",
		width: '50',
		height: '50',
	}, props);
	return (<svg width={props.width} height={props.height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13 43C15.2091 43 17 41.2091 17 39C17 36.7909 15.2091 35 13 35C10.7909 35 9 36.7909 9 39C9 41.2091 10.7909 43 13 43Z" stroke={props.color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M37 15C39.2091 15 41 13.2091 41 11C41 8.79086 39.2091 7 37 7C34.7909 7 33 8.79086 33 11C33 13.2091 34.7909 15 37 15Z" stroke={props.color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M25 39H34C35.8565 39 37.637 38.2625 38.9497 36.9497C40.2625 35.637 41 33.8565 41 32C41 30.1435 40.2625 28.363 38.9497 27.0503C37.637 25.7375 35.8565 25 34 25H18C16.1435 25 14.363 24.2625 13.0503 22.9497C11.7375 21.637 11 19.8565 11 18C11 16.1435 11.7375 14.363 13.0503 13.0503C14.363 11.7375 16.1435 11 18 11H25" stroke={props.color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>)
}

