import MyMap from './MyMap.js';
import { apiUrl, sendRequest, arrayFactory, utilLog } from './MyUtil.js';
import markerIcon from './images/Marker_black.svg';


class MyStudentMap extends MyMap {
	log(...msg){
		msg.unshift('MyStudentMap:')
		utilLog.apply(null, msg)
	}
	constructor(props){
		super(props);
		this.log('constructor')
		this.lastLat = null
		this.lastLng = null
		this.matchedPoints = []
		this.student={}
		this.selectedPointCode=''
		this.directionsService = new this.google.maps.DirectionsService()
		this.directionsRenderer = new this.google.maps.DirectionsRenderer({
			suppressMarkers: true,
			// preserveViewport: true
		})
		this.isUpdateLatLng = false
		this.points = arrayFactory({
			addFunc: v => {
				this.log(v)
				v.marker = this.createMarker(v.data.lat, v.data.lng)
				v.marker.addListener('click', e => {
					// let triggerByClickMarker = (e !== true)
					// if(triggerByClickMarker){
					// 	// this.selectedPointCode = v.data.code
					// 	this.props.OnSelectedPointCodeUpdated(v.data.code)
					// 	return
					// }
					// this.log('clickListener of marker', args)
					// this.log('clickListener of marker', args[0] instanceof window.google.maps.MapMouseEvent)
					this.log('onclick', this.points.activeIndex, v.index, this.matchedPoints)
					if(this.matchedPoints.find(e=>e===v.data.code)){
						this.log('this is matched point')
						this.props.OnClickStudentMarker()
						// v.activate(true)
					}
					else if(this.points.activeIndex === -1){
						this.log('there is no active marker')
						v.activate(true)
					}
					else if(this.points.activeIndex !== v.index){
						// this.log(this.points.get(this.points.activeIndex))
						this.log('this is non-active marker')
						this.points.get(this.points.activeIndex).deactivate()
						v.activate(true)
					}
					else{
						// if(triggerByClickMarker){
							this.log('this is deactivating point')
							v.deactivate(true)
						// }
						// else{
						// 	this.log('this is deactivating point, try to activate marker')
						// 	v.activate(true)
						// }
					}
				})
				v.infoWindow  = this.createInfoWindow(v.data)
				v.activate = _ => {
					// if(this.matchedPoints.find(e=>e===v.data.code)){
					// 	// return
					// }
					// if(this.points.activeIndex > -1){
					// 	this.points.get(this.points.activeIndex).deactivate()
					// }
					// console.log(this.matchedPoints, v.data.code)
					// this.points.deactivateAll()
					// this.props.OnSelectedPointCodeUpdated(v.data.code)
					this.setState({ selectedPointCode: v.data.code})
					this.points.activeIndex = v.index
					v.infoWindow.open(this.map, v.marker)
					this.directionsService.route({
						origin: {
							lat: this.lat,
							lng: this.lng
						},
						destination: {
							lat: v.data.lat,
							lng: v.data.lng
						},
						travelMode: 'WALKING',

					}).then(response => {
						// add handling to check after the map is panned
						// here may be a bug
						window.google.maps.event.addListenerOnce(this.map, 'center_changed', _=>{
							window.google.maps.event.addListenerOnce(this.map, 'idle', _ => {
								let mapCenter = this.map.getCenter()
								if(mapCenter.lat() !== this.lat ||
									mapCenter.lng() !== this.lng){
									this.props.OnFingerPanMap()
								}
							})
						})
						this.directionsRenderer.setDirections(response)
						this.directionsRenderer.setMap(this.map)

					})
				}
				v.deactivate = triggerByClick => {
					// this.log('v.deactivate = triggerByClick', triggerByClick)
					// if(triggerByClick === true){
					// 	console.log('this.matchedPoints.find(e=>e===v.data.code)', this.matchedPoints.find(e=>e===v.data.code))
					// 	if(this.matchedPoints.find(e=>e===v.data.code)){
					// 		this.props.OnClickStudentMarker()
					// 		return
					// 	}
					// 	else{
					// 	}
					// }
					this.setState({ selectedPointCode: ''})
					// if(triggerByClick){
					// 	this.props.OnSelectedPointCodeUpdated('')
					// }
					this.points.activeIndex = -1
					v.infoWindow.close()
					this.directionsRenderer.setMap(null)
				}
			}
		})
		this.overlays = arrayFactory({
			addFunc: v => {
				let overlay = v.data
				overlay.active = false
				let bounds = new this.google.maps.LatLngBounds(
					new this.google.maps.LatLng(overlay.sw_lat, overlay.sw_lng),
					new this.google.maps.LatLng(overlay.ne_lat, overlay.ne_lng),
				);
				v.overlayView = new USGSOverlay(bounds)
				v.overlayView.setImageSrc(apiUrl(overlay.image), overlay.alpha_value / 100)
				v.overlayView.setMap(overlay.active ? this.map : null)
			}
		})
		class USGSOverlay extends this.google.maps.OverlayView {
			constructor(bounds) {
				super();
				this.bounds = bounds;
				this.img = document.createElement("img");
			}
			/**
			* onAdd is called when the map's panes are ready and the overlay has been
			* added to the map.
			*/
			onAdd() {
				this.div = document.createElement("div");
				this.div.id = "divhahahaha";
				this.div.style.borderStyle = "none";
				this.div.style.borderWidth = "0px";
				this.div.style.position = "absolute";
				// Create the img element and attach it to the div.
				if(!this.img){
					this.img = document.createElement("img");
				}
				this.img.style.width = "100%";
				this.img.style.height = "100%";
				this.img.style.position = "absolute";
				this.div.appendChild(this.img);
				// Add the element to the "overlayLayer" pane.
				const panes = this.getPanes();
				panes.overlayLayer.appendChild(this.div);
			}
			setBounds(bounds) {
				// use this mehod to move the overlay
				this.bounds = bounds
				this.draw()
			}
			setImageSrc(imageSrc, alpha) {
				// user this function to put png file overlay
				if(typeof alpha === 'undefined' || alpha == null){
					alpha = 1
				}
				this.img.src = imageSrc
				this.img.style.opacity = alpha
			}
			draw() {
				// We use the south-west and north-east
				// coordinates of the overlay to peg it to the correct position and size.
				// To do this, we need to retrieve the projection from the overlay.
				// if(!this.bound)return
				const overlayProjection = this.getProjection();
				// Retrieve the south-west and north-east coordinates of this overlay
				// in LatLngs and convert them to pixel coordinates.
				// We'll use these coordinates to resize the div.
				const sw = overlayProjection.fromLatLngToDivPixel(
					this.bounds.getSouthWest()
				);
				const ne = overlayProjection.fromLatLngToDivPixel(
					this.bounds.getNorthEast()
				);

				// Resize the image's div to fit the indicated dimensions.
				if (this.div) {
					// console.log(sw.x, sw.y, ne.x, ne.y)
					this.div.style.left = sw.x + "px";
					this.div.style.top = ne.y + "px";
					this.div.style.width = ne.x - sw.x + "px";
					this.div.style.height = sw.y - ne.y + "px";
				}
			}
			/**
			* The onRemove() method will be called automatically from the API if
			* we ever set the overlay's map property to 'null'.
			*/
			onRemove() {
				if (this.div) {
					this.div.parentNode.removeChild(this.div);
					delete this.div;
				}
			}
		}
		if(!this.state){
			this.state = {}
		}
		this.state.selectedPointCode = ''
	}
	onPositionInit(lat, lng){
		this.log('onPositionInit')
		this.student.marker=this.createMarker(this.lat, this.lng, {
			icon: markerIcon
		})
		if(this.props.OnClickStudentMarker){
			this.student.marker.addListener('click', () => {
				this.props.OnClickStudentMarker()
			})
		}
		this.checkMatchedPoint()
		this.onPositionUpdated(lat, lng)
	}
	onPositionUpdated(lat, lng){
		this.log('onPositionUpdated')
		if(lat === null || lng === null){
			return
		}
		else{
			this.isUpdateLatLng = true
		}
		let recordPosition = isNaN(parseFloat(this.lastLat)) || isNaN(parseFloat(this.lastLng))
		if(!recordPosition){
			let movedDistance = this.google.maps.geometry.spherical.computeDistanceBetween(
				new this.google.maps.LatLng({
				lat: lat,
				lng: lng,
			}), new this.google.maps.LatLng({
				lat: this.lastLat,
				lng: this.lastLng,
			}))
			recordPosition = movedDistance > 10
		}
		if(recordPosition){
			// send request
			let fd = new FormData()
			fd.set('lat', lat)
			fd.set('lng', lng)
			sendRequest(`student/trip/self/position`, {
				method: 'post', 
				data: fd
			})
			this.lastLat = lat
			this.lastLng = lng
		}
		if(this.student.marker){
			this.student.marker.setPosition({
				lat: lat,
				lng: lng,
			})
		}
		if(this.props.isLockedCenterMarker){
			this.map.panTo({
				lat: lat,
				lng: lng,
			})
		}
		this.lat = lat
		this.lng = lng
		this.checkMatchedPoint()
	}
	checkMatchedPoint(){
		this.log('checkMatchedPoint')
		// this.lat and this.lng must not be null
		let lat = this.lat
		let lng = this.lng
		// filter out answerable point
		let matchedPoints = this.points.getAll().filter(v=>{
			let pointLatLng = new this.google.maps.LatLng({
				lat: v.data.lat,
				lng: v.data.lng,
			})
			let studentLatLng = new this.google.maps.LatLng({
				lat: lat,
				lng: lng,
			})
			let distance = this.google.maps.geometry.spherical.computeDistanceBetween(pointLatLng, studentLatLng)
			return distance < v.data.distance * 1
		}).map(v=>v.data.code)
		this.log('matchedPoints', matchedPoints)
		this.log('this.matchedPoints', this.matchedPoints)
		if(matchedPoints.length !== this.matchedPoints.length || 
			matchedPoints.some(v=>this.matchedPoints.indexOf(v) === -1)){
			if(this.props.OnPointMatchedUpdated){
				this.log('OnPointMatchedUpdated')
				this.props.OnPointMatchedUpdated(matchedPoints)
			}
			this.matchedPoints = matchedPoints.map(v=>v)
		}
	}
	focusStudentMarker(){

		this.map.panTo(this.student.marker.getPosition())
	}
	componentDidMount(){
		super.componentDidMount()
		this.log('componentDidMount')
		// init function for position
		this.onPositionInit(this.props.lat, this.props.lng)
		// init function for points
		this.props.points.forEach(v=>{
			this.points.add(v)
		})
		this.props.overlays.forEach(v => {
			this.overlays.add(v)
		})
		this.checkMatchedPoint()
		// if the map is drag by user, trigger unlock focusUserMarker
		this.map.addListener('dragstart', _=>{
			this.props.OnFingerPanMap()
		});
		// this.map.addListener('center_changed', _=>{
		// 	this.log('this.isUpdateLatLng', this.isUpdateLatLng)
		// 	// console.log(this.map)
		// 	window.google.maps.event.addListenerOnce(this.map, 'idle', _ => {
		// 		let mapCenter = this.map.getCenter()
		// 		if(mapCenter.lat() !== this.lat ||
		// 			mapCenter.lng() !== this.lng){
		// 			this.props.OnFingerPanMap()
		// 		}
		// 	})
		// 	// if(!this.isUpdateLatLng && !this.byPass){
		// 		// this.props.OnFingerPanMap()
		// 	// }
		// 	// this.isUpdateLatLng = false
		// })
	}
	componentDidUpdate(){
		super.componentDidUpdate()
		this.log('componentDidUpdate')
		// position of student is updated
		if(this.props.lat !== null && this.props.lng !== null){
			if(this.props.lat !== this.lat || this.props.lng !== this.lng){
				// if the newest lat or lng is differ from original one
				// call position update
				this.onPositionUpdated(this.props.lat, this.props.lng)
			}
		}
		// number of point is updated
		if(this.props.points.length !== this.points.getAll().length){
			this.points.getAll(v=>v.marker.setMap(null))
			this.points.clear()
			this.props.points.forEach(v=>{
				this.points.add(v)
			})
			if(this.student.marker){
				this.student.marker.setZIndex(this.points.getAll().length)
			}
		}
		// number of overlays is updated
		if(this.overlays.getLength() === 0){
			this.props.overlays.forEach(v => {
				this.overlays.add(v)
			})
		}
		// number of active overlay is changed => visibility of some overlay is changed 
		else if(this.props.overlays.filter(v => v.active).length !==
			this.overlays.getAll().filter(o=>o.data.active).length){
			this.props.overlays.forEach(v => {
				this.overlays.findSet(o=>o.data.code === v.code, v)
			})
			this.overlays.getAll().forEach(v => {
				v.overlayView.setMap(v.data.active ? this.map : null)
			})
		}
		this.log(this.state)
		// selected point is updated
		// update here is from task item list
		if(this.props.selectedPointCode !== this.selectedPointCode){
			let selectedPointCode = this.props.selectedPointCode
			// if the new selected point code is empty, 
			// we will get current active point
			if(selectedPointCode.length === 0){
				selectedPointCode = this.selectedPointCode
			}
			// find point and trigger click if existed
			let point = this.points.find(p=>p.data.code === selectedPointCode)
			if(point){
				this.google.maps.event.trigger(point.marker, 'click', true)
			}
			this.selectedPointCode = this.props.selectedPointCode
		}
		else if(this.state.selectedPointCode !== this.selectedPointCode){
			this.selectedPointCode = this.state.selectedPointCode
			this.props.OnSelectedPointCodeUpdated(this.selectedPointCode)
		}
		if(this.props.isLockedCenterMarker !== this.isLockedCenterMarker){
			this.isLockedCenterMarker = this.props.isLockedCenterMarker
			if(this.isLockedCenterMarker){
				// this.isUpdateLatLng = true
				this.focusStudentMarker()
			}
		}
	}
	componentWillUnmount(){
		super.componentWillUnmount()
		this.log('componentWillUnmount')
		this.lastLat = null
		this.lastLng = null
		this.matchedPoints = []
	}
}

export default MyStudentMap;