import React from 'react';
import './StudentApp.css';
import MyStudentMap from './MyStudentMap.js';
import TaskPanel from './TaskPanel.js';
import { Wrapper } from "@googlemaps/react-wrapper";
import { 
  BaseComponent, 
  utilLog, 
  AuthContext, 
  google_api_key 
} from './MyUtil.js';
// import {  } from './Login.js';

import Drawer from '@material-ui/core/Drawer';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import TimerSvg from './images/Timer.svg'
import Timer from './images/Timer'
// import RouteSvg from './images/Route.svg'
import Route from './images/Route'
// import ScoreSvg from './images/Score.svg'
import Score from './images/Score'

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';

import { Modal, OverlayTrigger, Popover, ListGroup } from 'react-bootstrap'
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Fab from '@material-ui/core/Fab';
import LayersIcon from '@material-ui/icons/Layers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import MyLocationIcon from '@material-ui/icons/MyLocation';
import ArrayKeyBoard from './ArrayKeyBoard'

import { text, parse } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import MoreVertIcon from '@material-ui/icons/MoreVert';

import GoldBadge from './images/gold.png';
import SliverBadge from './images/silver.png';
import BronzeBadge from './images/bronze.png';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';


let FontAwesomeText = props => {
  utilLog('props: ', props)
  const one = text('', {
    classes: Object.entries({
      'fa-inverse': props.inverse
    }).filter(v=>v[1]).map(v=>v[0]),
    // transform: {
    //   size: 11,
    //   x: 0,
    //   y: -4
    // }
    transform: parse.transform(props.transform)
  })
  const oneInfo = one.abstract[0]
  const oneStyle = oneInfo.attributes.style.split(';').map(v=>v.trim()).filter(v=>v.length > 0).reduce((a, c)=>{
    let [key, value] = c.split(':')
    a[key.trim().replaceAll(/-([a-z])/ig, (_,a)=>a.toUpperCase())] = value.trim()
    return a
  }, {})
  return React.createElement(
    oneInfo.tag, 
    {
      className: oneInfo.attributes.class,
      style: oneStyle,
    },
    props.text
  )
}
class StudentApp extends BaseComponent{
  log(...msg){
    msg.unshift('StudentApp:')
    utilLog.apply(null, msg)
  }
  constructor(props){
    super(props)
    this.log('constructor')
    this.state = {
      trip: {
        init: false,
        name: '',
        description: '',
        type: 'ongoing'
      },
      lat: null,
      lng: null,
      current_tasks: [],
      db_tasks: [],
      tasks: [],
      points: [],
      overlays: [],
      selectedPointCode: '',
      isDrawerOpen: false,
      openTripModal: false,
      openTaskModal: false,
      openStatusModal: false,
      openRankingModal: false,
      openTaskPanel: false,
      isLockedCenterMarker: true,
      visibleHeight: 0,
      top_rank: [],
      my_rank: {},
      score_level: '',
      time_level: ''
    }
    this.trip = {}
  }
  componentDidMount(){
    this.log('componentDidMount')
    this.sendRequest(`student/trip`).then(result=>{
      result = Object.assign({}, result, {init: true})
      this.setState({
        trip: result,
        openTripModal: true
      })
      this.trip = result
      if(result.type === 'ongoing'){
        navigator.geolocation.getCurrentPosition(position=>{
          this.setState({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          this.log('position is initialized')
          if(this.props.OnPositionUpdated){
            this.props.OnPositionUpdated(position.coords)
          }
          this.watchId = navigator.geolocation.watchPosition(position=>{
            this.log('position is updated')
            if(this.props.OnPositionUpdated){
              this.props.OnPositionUpdated(position.coords)
            }
            this.setState({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
          })
        }, error => {
          if(error.code === 1){
            // this.sendRequest('student/front-end-error', {

            // })
            alert('Please enable location permission and refresh the page.');
          }
        }, {
          enableHighAccuracy: true
        })
      }
    })
    this.sendRequest('student/trip/points').then(r => {
      // console.log(r)
      let points=[]
      r.forEach(v=>{
        points.push(v)
      })
      let result=points.reduce((a,c)=>({lat: a.lat+c.lat, lng: a.lng+c.lng}), {lat: 0, lng: 0})
      if(this.trip.type === 'virtual'){
        this.setState({
          lat: result.lat/points.length + Math.random() * 0.001 - 0.0005,
          lng: result.lng/points.length + Math.random() * 0.001 - 0.0005,
          points: points
        })
        if(this.props.OnPositionUpdated){
          this.props.OnPositionUpdated({
            lat: result.lat/points.length,
            lng: result.lng/points.length
          })
        }
      }
      else{
        this.setState({
          points: points
        })
      }
    })
    this.sendRequest('student/trip/overlays').then(ogs => {
      // let overlays = []
      let overlaysPromise = Promise.resolve([])
      ogs.forEach(og => {
        overlaysPromise = overlaysPromise.then(overlays => {
          return this.sendRequest(`student/trip/overlays/${og.code}`).then(os =>{
            os.forEach(o => {
              o.name = og.name
              o.active = true
              overlays.push(o)
            })
            return overlays
          })
        })
      })
      return overlaysPromise
    }).then(overlays=>{
      this.setState({
        overlays: overlays
      })
    })
    this.sendRequest('student/trip/tasks').then(tasks=>{
      this.setState({
        db_tasks: tasks
      })
      return tasks
    })
    this.setState({visibleHeight: window.innerHeight - 64})
    window.addEventListener('resize', _=>{
      this.setState({visibleHeight: window.innerHeight - 64})
    })
  }
  componentDidUpdate(){
    this.log('componentDidUpdate')
  //   this.log('this.state.current_tasks.length > 0', this.state.current_tasks.length > 0)
  //   this.log('this.isOverlayOpened()', this.isOverlayOpened())
  //   if(this.state.current_tasks.length > 0 && this.isOverlayOpened()){
  //     NotificationManager.info('click me to open it', 'You have new tasks', 10000, () => {
  //       this.setState({
  //         isDrawerOpen: false,
  //         openTripModal: false,
  //         openTaskModal: false,
  //         openStatusModal: false,
  //         openRankingModal: false
  //       })
  //     });
  //   }
    this.log('this.state.trip.init', this.state.trip.init)
  }
  isOverlayOpened(){
    return this.state.isDrawerOpen || 
      this.state.openTripModal || 
      this.state.openTaskModal || 
      this.state.openStatusModal || 
      this.state.openRankingModal;
  }
  keyboardMove(key, speed){
    // console.log(key)
    var angelChange = x => x / (6371 * 1000) * 360 / Math.PI

    if(key === 'ArrowUp'){
      this.setState(state=>({lat: state.lat + angelChange(speed)}))
    }
    if(key === 'ArrowDown'){
      this.setState(state=>({lat: state.lat - angelChange(speed)}))
    }
    if(key === 'ArrowRight'){
      this.setState(state=>({lng: state.lng + angelChange(speed)}))
    }
    if(key === 'ArrowLeft'){
      this.setState(state=>({lng: state.lng - angelChange(speed)}))
    }
  }
  handleMatchedPoint(point_codes){
    // if(this.state.isDrawerOpen || 
    //   this.state.openTripModal || 
    //   this.state.openTaskModal || 
    //   this.state.openStatusModal || 
    //   this.state.openRankingModal){
    //   return false
    // }
    this.log('handleMatchedPoint, point_codes: ', point_codes)
    if(point_codes.length === 0){
      this.setState(state => ({current_tasks: []}))
    }
    let requests = point_codes.map(point_code => {
      // let code = point.code ? point.code : point.point_code
      return this.sendRequest(`student/trip/points/${point_code}/tasks`)
    })
    Promise.all(requests).then(result=>{
      let tasks = result.map((ts, i)=>ts.map(t=>{
        t.point_code = point_codes[i]
        return t
      })).reduce((a, c) => a.concat(c), [])
      this.log('handleMatchedPoint, tasks: ', tasks)
      this.setState(state => ({current_tasks: tasks}))
    })
  }
  openDrawer(){
    this.setState({'isDrawerOpen': true})
  }
  closeDrawer(){
    this.setState({'isDrawerOpen': false})
  }
  showTripModal(){
    this.setState({
      openTripModal: true
    })
  }
  showTaskModal(){
    let taskPromise = Promise.resolve(this.state.db_tasks);
    // if(this.state.tasks.length === 0){
    //   taskPromise = this.sendRequest('student/trip/tasks').then(tasks=>{
    //     this.setState({
    //       db_tasks: tasks
    //     })
    //     return tasks
    //   })
    // }
    taskPromise.then(tasks=>{
      this.sendRequest(`student/trip/self`).then(status=>{
        tasks = tasks.map(_task => {
          let task = {
            code: _task.task_code,
            point_code: _task.point_code,
            task_name: _task.task_name,
            point_name: _task.name,
            property: _task.order_property,
            hv_answer: status.tasks.find(t=>t.code === _task.task_code).hv_answer
          }
          if(task.property === 'sequence'){
            task.order = _task.order
          }
          return task
        })
        tasks.sort((a, b)=>{
          let a_order = a.property === 'sequence' ? a.order : Number.MAX_SAFE_INTEGER
          let b_order = b.property === 'sequence' ? b.order : Number.MAX_SAFE_INTEGER
          return a_order - b_order
        })
        this.setState({
          tasks: tasks,
          openTaskModal: true
        })
      })
    })
  }
  showStatusModal(){
    this.sendRequest('student/trip/status').then(r=>{
      this.context.onLogin({
        score: r.score
      })
      let score_level = '', time_level = ''
      this.log(r.score_percentage, r.time_percentage)
      if(r.score_percentage !== undefined){
        if(r.score_percentage > 85){
          score_level = 'gold'
        }
        else if(r.score_percentage > 50){
          score_level = 'sliver'
        }
        else{
          score_level = 'bronze'
        }
      }

      if(r.time_percentage !== undefined){
        if(r.time_percentage > 85){
          time_level = 'gold'
        }
        else if(r.time_percentage > 50){
          time_level = 'sliver'
        }
        else{
          time_level = 'bronze'
        }
      }
      this.log(score_level, time_level)
      this.setState({
        openStatusModal: true,
        score_level: score_level,
        time_level: time_level
      })
    })
  }
  showRankingModal(){
    this.sendRequest('student/trip/ranking').then(r=>{
      this.log(r)
      // this.context.onLogin({
      //   score: r.score
      // })
      this.setState({
        openRankingModal: true,
        top_rank: r.top_rank,
        my_rank: r.my_rank
      })
    })
  }
  findTaskPoint(task){
    this.setState(state => ({
      // make sure to trigger componentUpdate of MyStudentMap
      // if the new task to find is active, we will tried to deactivate that point
      selectedPointCode: state.selectedPointCode !== task.point_code ? task.point_code : '',
      // selectedPointCode: task.point_code,
      openTaskModal: false
    }))
  }
  logout(){
    if(!window.confirm('Exit?')){
      return
    }
    this.sendRequest('student/trip/exit', {
      method: 'post', 
    }).then(_=>{
      this.context.onLogin({
        logined: false,
        name: ''
      })
    })
  }
  isGPS(){
    // return false
    return this.state.trip.type === 'ongoing'
  }
  render() {
    let TaskStatusIcon = ({done}) => {
      return done ? <DoneIcon /> : <CloseIcon />;
    }
    let TaskPropertyIcon = ({property}) => {
      if(property === 'timed')return <Timer width="30" height="30" color="gray" />
      if(property === 'score')return <Score width="30" height="30" color="gray" />
      if(property === 'sequence')return <Route width="30" height="30" color="gray" />
      return '';
    }
    let TaskListItem = ({key, task}) => {
      return( 
        <ListItem button 
        key={key} 
        onClick={_=>this.findTaskPoint(task)}>
          <ListItemText elevation={0} >
            <Typography variant="h6" className="oneLineText">{`Task: ${task.task_name}`}</Typography>
            <Typography className="oneLineText">{`Checkpoint: ${task.point_name}`}</Typography>
          </ListItemText>
            {/*<ListItemText 
            primary={`Task: ${task.task_name}`}
            secondary={`Point: ${task.point_name}`} />*/}
          <ListItemIcon style={{justifyContent: 'flex-end'}}>
            <TaskPropertyIcon property={task.property} />
            <TaskStatusIcon done={task.hv_answer} />
          </ListItemIcon>
        </ListItem>
        )
    }
    let TaskTimelineItem = ({key, task, isLast}) => {
      let activeStyle = {
        backgroundColor: '#3f51b5',
        borderColor: 'transparent',
        color: 'white'
      }
      let deactiveStyle = {}
      return( 
        <TimelineItem
          key={key} 
          onClick={(e)=>this.findTaskPoint(task)} className="taskTimeLineItem">
          <TimelineSeparator>
            <TimelineDot style={task.hv_answer ? activeStyle : deactiveStyle}>
              <TaskStatusIcon done={task.hv_answer} />
            </TimelineDot>
            {!isLast && <TimelineConnector style={task.hv_answer ? activeStyle : deactiveStyle} />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={0} style={task.hv_answer ? {} : {color: 'gray'}}>
              <Typography variant="h6" className="oneLineText">{`Task: ${task.task_name}`}</Typography>
              <Typography className="oneLineText">{`Checkpoint: ${task.point_name}`}</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        )
    }
    return (
      <React.Fragment>
        {/*<Header></Header>*/}
        <div style={{
          position: 'relative', 
          zIndex: 1000, 
        }}>
          <AppBar 
            position="relative" 
            style={{
              backgroundColor: 'white',
              color: 'black',
            }}>
            <Toolbar style={{
              height: '64px'
            }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.openDrawer.bind(this)}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Presentria GO
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <Wrapper 
          apiKey={google_api_key} 
          libraries={['geometry','places']}
        >
          <div style={{height: `${this.state.visibleHeight}px`}}>
            {
              this.state.lat && 
              this.state.lng && 
              <MyStudentMap 
                points={this.state.points}
                overlays={this.state.overlays}
                lat={this.state.lat} 
                lng={this.state.lng} 
                OnPointMatchedUpdated={this.handleMatchedPoint.bind(this)}
                OnClickStudentMarker={()=>{
                  this.setState({openTaskPanel: true})
                }}
                OnSelectedPointCodeUpdated={
                  newPointCode=>this.setState({
                    selectedPointCode:newPointCode
                  })
                }
                selectedPointCode={this.state.selectedPointCode}
                isLockedCenterMarker={this.state.isLockedCenterMarker}
                OnFingerPanMap={_=>this.setState({isLockedCenterMarker: false})}
              />
            }
            {
              !(this.state.lat && this.state.lng) && 
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}>
                <div>Please reload the page.</div>
              </div>
            }
          </div>
        </Wrapper>
        <div 
          style={{
            position: 'fixed', 
            'right': '24px', 
            'bottom': !this.isGPS() ? '194px' : '24px',
            width: 0
          }}
        >
          {this.state.overlays.length > 0 && <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
              <Popover>
                <Popover.Content>
                  <div style={{
                    maxHeight: '50vh',
                    maxWidth: '100vw'
                  }}>
                    <ListGroup variant="flush">
                      {this.state.overlays.map((v, k)=>
                        <ListGroup.Item
                          key={k}
                          onClick = {()=>this.setState(state => {
                            return {overlays: state.overlays.map((v1, k1) => {
                              return k1 === k
                                 ? Object.assign({}, v1, { active : !v1.active })
                                 : v1
                            })}
                          })}
                        >
                        {v.active && <VisibilityIcon />}
                        {!v.active && <VisibilityOffIcon />}
                        {v.name}
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <Fab style={{
              display: 'block',
              margin: '20px 0',
              position: 'relative',
              left: '-56px'
            }}><LayersIcon /></Fab>
          </OverlayTrigger>}
          <Fab 
            style={{
              display: 'block',
              margin: '20px 0',
              position: 'relative',
              left: '-56px',
              color: this.state.isLockedCenterMarker ?'#4173e8' : 'black'
            }} 
            onClick={_=>{
              this.setState({isLockedCenterMarker: true})
          }}><MyLocationIcon /></Fab>
        </div>
        {!this.isGPS() && 
          <ArrayKeyBoard
          style={{
            position: 'fixed',
            'right': '24px', 
            'bottom': '24px',
            height: '170px', 
            width: '120px'
          }}
          onArrayKeyPress={(key, speed) => this.keyboardMove(key, speed)} />
        }
        {<TaskPanel 
          tasks={this.state.current_tasks}
          show={this.state.openTaskPanel}
          allowShow={this.state.trip.init && !this.isOverlayOpened()}
          OnClose={_=>{this.setState({openTaskPanel: false})}}
          OnNotify={_=>{
            NotificationManager.info('', 'Click here to view your task(s).', 10000, () => {
              this.setState({
                isDrawerOpen: false,
                openTripModal: false,
                openTaskModal: false,
                openStatusModal: false,
                openRankingModal: false,
                openTaskPanel: true
              })
            })
          }}
        />}
        <Drawer anchor={'left'} open={this.state.isDrawerOpen} onClose={this.closeDrawer.bind(this)}>
          <div
            role="presentation"
            onClick={this.closeDrawer.bind(this)}
            onKeyDown={this.closeDrawer.bind(this)}
          >
            <List>
              <ListItem button key={'0'} onClick={this.showTripModal.bind(this)}>
                <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                <ListItemText primary={'Trip Information'} />
              </ListItem>
              <ListItem button key={'1'} onClick={this.showTaskModal.bind(this)}>
                <ListItemIcon><FormatListBulletedOutlinedIcon /></ListItemIcon>
                <ListItemText primary={'Task List'} />
              </ListItem>
              <ListItem button key={'2'} onClick={this.showStatusModal.bind(this)}>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary={'My Status'} />
              </ListItem>
              {/*<ListItem button key={'3'} onClick={this.showRankingModal.bind(this)}>
                <ListItemIcon>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCertificate} transform="up-4" color="#777">
                    </FontAwesomeIcon>
                    <FontAwesomeIcon icon={faBookmark} transform="down-4" color="#757575" />*/}
                    {/*<span className="fa-layers-text fa-inverse" data-fa-transform="shrink-11 up-4">1</span>*/}
                   {/* <FontAwesomeText text='1' inverse transform="shrink-6 up-5" />
                  </span>
                </ListItemIcon>
                <ListItemText primary={'Ranking'} />
              </ListItem>*/}
              <ListItem button key={'4'} onClick={this.logout.bind(this)}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <Modal 
          show={this.state.openTripModal} 
          onHide={() => {
            this.setState({openTripModal: false})
          }}
          // onExited={_ => {
          //   this.setState(state =>({
          //     trip: Object.assign({init: true}, state.trip),
          //   }))
          // }}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Trip Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography variant="h6" noWrap>Session ID: {this.state.trip.session_id}</Typography>
            <Typography variant="h6" noWrap>Name: {this.state.trip.name}</Typography>
            {this.state.trip.description.length > 0 &&
              <div>
                <Typography variant="h6" noWrap>Description: </Typography>
                <Typography variant="subtitle1" style={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap'
                }}>{this.state.trip.description}</Typography>
              </div>
            }
          </Modal.Body>
        </Modal>
        <Modal show={this.state.openTaskModal} onHide={() => {this.setState({openTaskModal: false})}}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Task List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List>
              {this.state.tasks
                .filter(t=>t.property !== 'sequence')
                .map((task, i) => (
                  <TaskListItem key={`task_${i}`} task={task} />
                ))
              }
            </List>
            <Timeline align="right" style={{paddingLeft: 0, paddingRight: 0}}>
              {this.state.tasks
                .filter(t=>t.property === 'sequence')
                .map((task, i, a) => (
                  <TaskTimelineItem 
                    key={`task_${i}`} 
                    task={task} 
                    isLast={i === a.length - 1}
                  />
                ))}
            </Timeline>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.openStatusModal} onHide={() => {this.setState({openStatusModal: false})}}>
          <Modal.Header closeButton={true}>
            <Modal.Title>My Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography variant="h6" noWrap>Name: {this.context.status.name}</Typography>
            <Typography variant="h6" noWrap>Score: {this.context.status.score}</Typography>
            <div style={{
              display: 'flex'
            }}>
              {this.state.score_level.length > 0 &&
                <div style={{flex: 1}}>
                  <div style={{textAlign: 'center'}}>
                    Score
                  </div>
                {this.state.score_level === 'gold' && 
                    <img style={{width: '100%'}} src={GoldBadge} />
                }
                {this.state.score_level === 'sliver' && 
                    <img style={{width: '100%'}} src={SliverBadge} />
                }
                {this.state.score_level === 'bronze' && 
                    <img style={{width: '100%'}} src={BronzeBadge} />
                }
                </div>
              }
              {this.state.time_level.length > 0 &&
                <div style={{flex: 1}}>
                  <div style={{textAlign: 'center'}}>
                    Time
                  </div>
                {this.state.time_level === 'gold' && 
                    <img style={{width: '100%'}} src={GoldBadge} />
                }
                {this.state.time_level === 'sliver' && 
                    <img style={{width: '100%'}} src={SliverBadge} />
                }
                {this.state.time_level === 'bronze' && 
                    <img style={{width: '100%'}} src={BronzeBadge} />
                }
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.openRankingModal} onHide={() => {this.setState({openRankingModal: false})}}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Ranking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {/*
            answered_task: "4"
            rank: "1"
            score: "25"
            session_code: "f28ba11d-00b7-11ec-a7c5-f0d4e2eb5308"
            student_id: "eee"
            student_name
          */}
          <table style={{width: '100%'}}>
          {this.state.top_rank.map((v, k) => (
            <tr style={v.session_code === this.state.my_rank.session_code ? {
              backgroundColor: '#E1E1E1'
            } : {}}>
              <td>{v.rank}</td>
              <td>{`${v.student_name}(${v.student_id})`}</td>
              <td>{v.score}</td>
              <td>{parseInt(v.answered_task * 100 / this.state.db_tasks.length)+'%'}</td>
              {/*<ListItemText primary={`${v.student_name}(${v.student_id})`} />
              <ListItemText primary={v.score} />
              <ListItemText primary={parseInt(v.answered_task * 100 / this.state.db_tasks.length)+'%'} />*/}
            </tr>
            ))}
            {this.state.top_rank.every(rank => rank.rank !== this.state.my_rank.rank) && <tr><td colspan='4' style={{textAlign: 'center', 'fontSize': '20pt'}}>
              <MoreVertIcon />
            </td></tr>}
            {this.state.top_rank.every(rank => rank.rank !== this.state.my_rank.rank) && <tr style={{
              backgroundColor: '#E1E1E1'
            }}>
              <td>{this.state.my_rank.rank}</td>
              <td>{`${this.state.my_rank.student_name}(${this.state.my_rank.student_id})`}</td>
              <td>{this.state.my_rank.score}</td>
              <td>{parseInt(this.state.my_rank.answered_task * 100 / this.state.db_tasks.length)+'%'}</td>
              {/*<ListItemText primary={`${v.student_name}(${v.student_id})`} />
              <ListItemText primary={v.score} />
              <ListItemText primary={parseInt(v.answered_task * 100 / this.state.db_tasks.length)+'%'} />*/}
            </tr>}
          </table>
          </Modal.Body>
        </Modal>
        <NotificationContainer />
      </React.Fragment>
    );
  }
  componentWillUnmount(){
    this.log('componentWillUnmount')
    navigator.geolocation.clearWatch(this.watchId);
  }
}
StudentApp.contextType = AuthContext
export default StudentApp;

/*<div className="App">
<header className="App-header">
<img src={logo} className="App-logo" alt="logo" />
<p>
Edit <code>src/App.js</code> and save to reload.
</p>
<a
className="App-link"
href="https://reactjs.org"
target="_blank"
rel="noopener noreferrer"
>
Learn React
</a>
</header>
</div>*/
