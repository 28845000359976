import React from 'react';
import './ArrayKeyBoard.css';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class ArrayKeyBoard extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			speed: 10
		}
	}
	componentDidMount(){
	    window.document.body.addEventListener('keydown', e => {
	      this.onArrayKeyPress(e.key)
	    })
	}
	onArrayKeyPress(key){
		this.props.onArrayKeyPress(key, this.state.speed)
	}
	speedUp(){
		this.setState(state => {
			let newSpeed = state.speed * 10
			newSpeed = Math.min(newSpeed, 10000)
			return {
				speed: newSpeed
			}
		})
	}
	speedDown(){
		this.setState(state => {
			let newSpeed = state.speed / 10
			newSpeed = Math.max(newSpeed, 1)
			return {
				speed: newSpeed
			}
		})
	}
	render() {
		return (
		<div id="array-keyboard" style={this.props.style}>
			<div style={{
				display: 'flex',
				'justifyContent': 'space-around',
				height: '40px',
				backgroundColor: '#E0E0E0',
				borderRadius: '20px',
				marginBottom: '10px'
			}}>
				<div style={{
					flex: '40px 0', 
					display: 'flex', 
					cursor: 'pointer', 
					justifyContent: 'center', 
					alignItems: 'center'
				}} onClick={_ => this.speedDown()}>
					<RemoveIcon />
				</div>
				<div style={{
				    display: 'flex',
				    flex: '1 1 0%',
				    alignItems: 'center',
				    justifyContent: 'center',
				}}>
					<span id="speedText">{this.state.speed}</span>
				</div>
				<div style={{
					flex: '40px 0', 
					display: 'flex', 
					cursor: 'pointer', 
					justifyContent: 'center', 
					alignItems: 'center'
				}} onClick={_ => this.speedUp()}>
					<AddIcon />
				</div>
			</div>
			<div style={{
				display: 'flex', 
				'flexDirection': 'column',
				'textAlign': 'center', 
			}}>
				<div style={{
					flex: 1
				}}>
					<Fab 
						size="small" 
						onClick={_ => this.onArrayKeyPress('ArrowUp')}
					>
						<KeyboardArrowUpIcon />
					</Fab>
				</div>
				<div style={{
					display: 'flex', 
					flex: 1
				}}>
					<div style={{flex: 1}}>
						<Fab 
							size="small" 
							onClick={_ => this.onArrayKeyPress('ArrowLeft')}
						>
							<KeyboardArrowLeftIcon />
						</Fab>
					</div>
					<div style={{flex: 1}}></div>
					<div style={{flex: 1}}>
						<Fab 
							size="small" 
							onClick={_ => this.onArrayKeyPress('ArrowRight')}
						>
							<KeyboardArrowRightIcon />
						</Fab>
					</div>
				</div>
				<div style={{
					flex: 1
				}}>
					<Fab 
						size="small" 
						onClick={_ => this.onArrayKeyPress('ArrowDown')}
					>
						<KeyboardArrowDownIcon />
					</Fab>
				</div>
			</div>
		</div>);
	}
}
export default ArrayKeyBoard;
