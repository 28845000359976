import React from 'react';
import { 
	Form, 
	Button, 
	Alert
} from 'react-bootstrap'
import './TaskPanel.css';
import { utilLog } from './MyUtil'


class ChooseQuestion extends React.Component{
  log(...msg){
    msg.unshift('ChooseQuestion:')
    utilLog.apply(null, msg)
	}
	constructor(props){
		super(props)
		this.log('constructor')
		this.tasks = this.props.tasks
		this.state={
			show: false,
			targetTask: this.getTargetTask()
		}
	}
	componentDidMount(){
		this.log('componentDidMount')
	}
	componentDidUpdate(){
		this.log('componentDidUpdate')
		this.log(this.state.targetTask)
		if(this.props.tasks.length !== this.tasks.length || this.props.tasks.some(t => this.tasks.findIndex(t1=>t1.code === t.code) === -1)){
			this.tasks = this.props.tasks.map(t=>t)
			if(this.tasks.length > 0 && this.state.targetTask === null){
				this.setState({
					targetTask: this.getTargetTask()
				})
			}
		}
	}
	getTargetTask(){
		this.log('getTargetTask', this.tasks)
		let targetTask = this.tasks.find(t => !t.done && t.pass)
		if(!targetTask){
			targetTask = this.tasks[0]
		}
		return targetTask
	}
	handleChangeTargetTask(e){
		let newTargetTask = this.props.tasks.find(t=>t.code === e.target.value)
		this.setState({targetTask: newTargetTask})
	}
	render(){
		return(
			<Form>
				{this.props.tasks.length === 1 && <div>You have a task</div>}
				{this.props.tasks.length > 1 && <div>You have {this.props.tasks.length} tasks</div>}
				<Form.Group controlId="exampleForm.SelectCustom">
				    <Form.Label>Task</Form.Label>
					<Form.Control 
						as="select" 
						onChange={this.handleChangeTargetTask.bind(this)} 
						value={this.state.targetTask.code}
					>
					{this.props.tasks.map(t=>{
						return 	<option 
									key={t.code} 
									value={t.code}
								>{t.name}</option>
					})}
					</Form.Control>
				</Form.Group>
				{this.state.targetTask.description.length > 0 && 
					(<div>
						<div>Description</div>
						<div dangerouslySetInnerHTML={{__html: this.state.targetTask.description}}></div>
					{/*{this.state.targetTask.type === 'upload' && 
						<div>{this.state.targetTask.description}</div>
					}
					{this.state.targetTask.type !== 'upload' && 
					}*/}
					</div>)
				}
				<Alert 
					key="question_done" 
					variant="info" 
					show={this.state.targetTask.done}
				>
					This task is completed.
				</Alert>
				<Alert 
					key="question_wrong" 
					variant="danger" 
					show={!this.state.targetTask.pass}
				>
					{this.state.targetTask.error}
				</Alert>

				<div style={{textAlign: 'right'}}>
					{this.state.targetTask.pass && <Button 
						variant="success" 
						disabled={!this.state.targetTask.pass} 
						onClick={()=>this.props.OnConfirmChooseing(this.state.targetTask)}
					>View</Button>}
					<Button 
						variant="light" 
						onClick={() => this.props.onClickHide()}
					>Quit</Button>
				</div>
			</Form>
		)
	}
}
// ChooseQuestion.defaultProps={
// 	targetTask: null,
// 	tasks: []
// }
export default ChooseQuestion;
