import React from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { 
	utilLog, 
  // AuthContext, 
  	BaseComponent 
} from './MyUtil.js';
import './Login.css';
import logo from './images/Logo.svg'

class Login extends BaseComponent{
	// static contextType = AuthContext
	log(...msg){
		msg.unshift('Login:')
		utilLog.apply(null, msg)
	}
	constructor(props){
		super(props)
		this.log('constructor')
		this.loginForm = React.createRef();
		this.state={
			hvError: false,
			errorMsg: '',
			defaultSessionId: ''
		}
	}
	render() {
		return (
			<React.Fragment>
				<div id="loginDiv">
					<div>
						<div style={{
							textAlign: 'center',
							paddingBottom: '20px'
						}}>
							{/* eslint-disable-next-line */}
							<img style={{
								width: '60%'
							}} src={logo} />
						</div>
						<Form ref={this.loginForm}>
							<Form.Group controlId="session">
								<Form.Label>Session ID</Form.Label>
								<Form.Control 
									type="text"
									name="session_id"
									defaultValue={this.state.defaultSessionId}
								/>
							</Form.Group>
							<Form.Group controlId="studentId">
								<Form.Label>Student ID</Form.Label>
								<Form.Control 
									type="text"  
									name="student_id"
								/>
							</Form.Group>
							<Form.Group controlId="studentName">
								<Form.Label>Student Name</Form.Label>
								<Form.Control 
									type="text"  
									name="student_name"
								/>
							</Form.Group>
						</Form>
						<Alert 
							show={this.state.hvError} 
							variant="danger"
						>{this.state.errorMsg}</Alert>
						<div id="buttonDiv">
							<Button 
								variant="success" 
								onClick={_=>this.login()}
							>Login</Button>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
	login(callback){
		let form = this.loginForm.current
		let fd = new FormData(form)
		this.sendRequest('student/trip/join', {
			method: 'post', 
			data: fd,
			onError: msg => {
				this.log(msg)
				if(msg === 'no trip'){
					this.context.onError('Missing Session ID')
				}
				else if(msg === 'session is ended'){
					this.context.onError('The session is ended')
				}
				else {
					this.context.onError('Unexpected Error.')
				}
			}
		}).then(r=>{
			this.log('then')
			this.context.onLogin({
				logined: true,
				name: fd.get('student_name')
			})
		}).catch(e => {})
	}
	componentDidMount(){
		this.log('componentDidMount')
		let query = window.location.search
		if(query.length > 0){
			this.log(query)
			query = query.substring(1)
			let queries = query.split('&').reduce((acc, cur) => {
				let [key, value] = cur.split('=')
				acc[key] = value
				return acc
			}, {})
			this.setState({
				defaultSessionId: queries['session-id']
			})
		}
	}
}
// Login.contentType = AuthContext
export { Login };
