import React from 'react'
const base_url='https://go.presentria.com/api/';
const google_api_key = 'AIzaSyCX9utf55IpelNm93tVO1AG85476CCik8E';

let apiUrl = (uri) => `${base_url}${uri}`

async function sendRequest(url, options){
  let method, data, autoShowLoader
  if(options){
    ({ method, data, autoShowLoader } = options)
  }
  // check if url contains protocol
  // xxx: is protocol format
  // usually the protocol is http or https
  // for blob file, protocol is blob, 
  // the url will show blob:<base-url>/<uuid-format>
  if(!/^.+?:\/\//.test(url)){
    url = apiUrl(url) 
  }
  if(method === undefined){
    method = 'get'
  }
  if(data === undefined){
    data = ''
  }
  if(autoShowLoader === undefined){
    autoShowLoader = true
  }
  let headers = new Headers()
  // headers.append('ureply-go-version', 'development')
  // headers.append('X-REQUESTED-WITH', 'xmlhttprequest')

  let body = {
    method: method,
    credentials: 'include',
    headers: headers
  }
  if(method === 'get'){
    if(data instanceof FormData){
      data = Array.from(data.entries()).map(v=>`${v[0]}=${encodeURIComponent(v[1])}`).join('&')
    }
    if(typeof data === 'string' && data.length > 0){
      if(url.indexOf('?') > -1){
        url = url+'&'
      }
      else{
        url = url+'?'
      }
      url = url + data
    }
  }
  else if(method === 'post'){
    if(data instanceof FormData){
      body.body = data
    }
    else if(data instanceof Object){
      body.body=Object.entries(data).reduce((a, c)=>{
        a.append(c[0], c[1])
        return a
      }, new FormData())
    }
  }
  // if(autoShowLoader){
  //   // $('#background-loading').show()
  //   loader.start Loading()
  // }
  let response = await fetch(url, body)
  utilLog(response)
  let status_code = response.status
  if(parseInt(status_code / 100) === 4){
    let result = await response.json()
    utilLog(result)  
    // if(this.setState){

    // }
    // if(options?.onNoPermission){
    //   options.onNoPermission()
    //   return
    // }
    let error = new Error()
    error.code = 1
    error.statusCode = status_code
    error.msg = result.error
    throw error
    // alert('please login')
    // window.location.assign('./login.html')
    // throw {
    //   code: 1,
    //   msg: 'please login'
    // }
  }
  if(response.headers.get('content-type').indexOf('json') > -1){
      let result = await response.json()
      if(result.success){
        return result.data
      }
      else{
        let error = new Error()
        error.code = 2
        error.statusCode = status_code
        error.msg = result.error
        throw error
        // if(options?.onError){
        //   options.onError(r.error)
        //   throw new Error(r.error)
        // }
      }
  }
  else if(response.headers.get('content-type').indexOf('text') > -1){
    return response.text()
  }
  else {
    return response
  }
    // if(autoShowLoader){
    //   loader.finishLoading()
    // }
}
const AuthContext = React.createContext({
  status: {},
  onLogin: () => {},
})
class BaseComponent extends React.Component{
  static contextType = AuthContext
  log(...msg){
    msg.unshift('BaseComponent:')
    utilLog.apply(null, msg)
  }
  sendRequest(url, options){
    if(options === undefined){
      options = {}
    }
    this.log('sendRequest')
    return sendRequest(url, options).catch(e => {
      this.log('catch')
      if(e.code === 1){
        if(options.onNoPermission){
          options.onNoPermission(e.msg)
        }
        else if(options.onError){
          options.onError(e.msg, 403)
        }
        else if(this.context.onNoPermission){
          this.context.onNoPermission(e.msg)
        }
        else if(this.context.onError){
          this.context.onError(e.msg)
        }
      }
      if(e.code === 2){
        if(options.onError){
          options.onError(e.msg)
        }
        else if(this.context.onError){
          this.context.onError(e.msg)
        }
      }
      return Promise.reject()
      // throw e
    })
  }
}
const arrayFactory = function({ addFunc, removeFunc, setFunc, key, ...methods }){
  let array = []
  let lock = false
  let self = {
    activeIndex: -1,
    add: async function(o){
      if(o !== undefined && o!== null){
        if(key !== undefined){
          // if key is exist in this array, we will first try to set value
          // if success, then it is done.
          // if not, add value
          let new_obj = this.findSet(v=>v.data[key] === o[key], o)
          if(new_obj !== null){
            return
          }
        }
        array.push({
          data: o,
          show: false,
          index: array.length
        })
      }
      if(!lock){
        lock = true
        array
        .filter(o=>!o.show)
        .map(async v => {
          v.show = true
          if(addFunc){
            v.data.ui = addFunc.call(this, v)
          }
          if(this._addFunc){
            this._addFunc(v)
          }
          return v
        })
        lock=false
      }
    },
    get: function(index){
      if(index < 0 || index >= array.length){
        return null
      }
      return array[index]
    },
    getAll: function(){
      return array
    },
    remove: function(index){
      if(index < 0 || index >= array.length){
        return null
      }
      let targetItem = array.splice(index, 1)[0]
      // targetItem.index = index
      if(removeFunc){
        // removeFunc(targetItem)
        removeFunc.call(this, targetItem)
      }
      return targetItem
    },
    set: function(index, value, overwrite){
      if(overwrite === undefined){
        overwrite = false
      }
      if(index < 0 || index >= array.length){
        return null
      }
      let old_data = Object.assign({}, array[index].data)
      if(overwrite){
        array[index].data = Object.assign({}, value)
      }
      else{
        array[index].data = Object.assign(array[index].data, value)
      }
      this.update(index, old_data)
      // array[index].show = false
    },
    findSet: function(matchFunc, ...others){
      let data = this.find(matchFunc)
      if(data === null){
        return null
      }
      others.unshift(data.index)
      return this.set.apply(this, others)
      // array[index].show = false
    },
    find: function(matchFunc){
      if(matchFunc === undefined){
        return null
      }
      let index = array.findIndex(matchFunc)
      if(index === -1){
        return null
      }
      let data = array[index]
      // data.index = index
      return data
    },
    findRemove: function(matchFunc){
      let data = this.find(matchFunc)
      if(data === null){
        return null
      }
      return this.remove(data.index)
    },
    clear: function(){
      array.forEach((v, k)=>{
        // v.index = k
        if(removeFunc){
          removeFunc.call(this, v)
        }
      })
      array = []
    },
    sort: function(cb){
      array.forEach(v=>{
        // removeFunc(v)
        v._visible = v.data.ui?.is(":visible")
        if(removeFunc){
          removeFunc.call(this, v)
        }
      })
      let _array = array.map(v=>Object.assign({}, v))
      _array = _array.sort((a, b) =>cb(a.data, b.data))
      _array.forEach((v, k)=>{
        if(addFunc){
          v.data.ui = addFunc.call(this, v)
          if(v._visible === false){
            v.data.ui.hide()
          }
          else{
            v.data.ui.show()
          }
          v._visible = undefined
        }
      })
    },
    filter: function(matchFunc){
      if(array.length > 0 && array[0].data.ui){
        let matchCount = 0
        array.forEach(v=>{
          if(matchFunc(v.data)){
            matchCount++
            v.data.ui.show()
          }
          else{
            v.data.ui.hide()
          }
        })
        if(matchCount > 0){
          // dont use hide method. It will override original css rule
          // this is not common usage. It need to be generalized
          array[0].data.ui.parent().siblings('.empty-msg').css('display', '')
        }
        else{
          array[0].data.ui.parent().siblings('.empty-msg').show()
        }
      }
      else{
        array.forEach(v=>{
          // removeFunc(v)
          if(removeFunc){
            removeFunc.call(this, v)
          }
        })
        let _array=array.filter(v=>matchFunc(v.data))
        if(_array.length)
        _array.forEach(v=>{
          if(addFunc){
            v.data.ui = addFunc.call(this, v)
          }
          // addFunc(v)
        })
      }
    },
    getLength: function(){
      return array.length
    },
    update: function(index, old_data){
      if(setFunc){
        setFunc.call(this, array[index], old_data)
      }
    }
  }
  if(methods !== undefined){
    Object.entries(methods).forEach(v=>{
      self[v[0]] = v[1].bind(self)
    })
  }
  return self
}
function utilLog(...msg){
  if(false){
    console.log(...msg)
  }
}

export { 
  apiUrl, 
  google_api_key, 
  sendRequest, 
  arrayFactory, 
  utilLog, 
  AuthContext, 
  BaseComponent 
}
