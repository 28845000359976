import React from 'react';
import './TaskFragment.css';
import {
	Form, 
	Button, 
	// Popover, 
	// OverlayTrigger, 
	Accordion,
	Alert } from 'react-bootstrap'
import { Slider } from '@material-ui/core';
import { sendRequest, utilLog } from './MyUtil.js';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import GoldBadge from './images/gold.png';
import SliverBadge from './images/silver.png';
import BronzeBadge from './images/bronze.png';

let Tipbtn = ({tips})=>{
	// let popover = (
	// 		<Popover id="popover-basic">
	// 				<Popover.Content>
	// 				<div dangerouslySetInnerHTML={{__html: tips}}></div>
	// 				</Popover.Content>
	// 		</Popover>
	// 	)
	// return 	<OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
	// 				<Button variant="info">Tips</Button>
	// 			</OverlayTrigger>
	return <Accordion>
		<div style={{textAlign: 'right'}}>
			<Accordion.Toggle as={Button} eventKey="0">Tips</Accordion.Toggle>
		</div>
		<Accordion.Collapse eventKey="0">
			<div>
				<h6>Tips:</h6>
	 			<div dangerouslySetInnerHTML={{__html: tips}}></div>
 			</div>
		</Accordion.Collapse>
	</Accordion>
}
let AnswerCell = ({answer, onClickHandle, isActive, disabled}) => {
	return <div 
		className={isActive ? 'activeAnswer' : ''} 
		onClick={()=>onClickHandle(answer)}
		style={disabled ? {
			opacity: 0.8
		} : {}}
	>
		{answer}
	</div>
}
class MCTask extends React.Component{
	log(...msg){
		msg.unshift('MCTask:')
		utilLog.apply(null, msg)
	}
	constructor(props){
		super(props)
		this.state={
			selectedAnswer: props.answer
		}
		this.onClickHandle = this.onClickHandle.bind(this)
		this.value = this.state.selectedAnswer
		this.log(this.props.onCanceled)
		this.log(this.props.onSelected)
	}
	onClickHandle(answer){
		utilLog(answer, 'is clicked')
		if(this.props.disabled){
			return
		}
		if(answer === this.state.selectedAnswer){
			this.props.onCanceled()
			this.setState({selectedAnswer: ''})
		}
		else{
			this.props.onSelected()
			this.setState({selectedAnswer: answer})
		}
	}
	componentDidUpdate(){
		utilLog('mc task update')
		this.value = this.state.selectedAnswer
	}
	render(){
		return <div id="mc-answer-container">
			{Array.from(new Array(this.props.numOfAnswer))
				.map((_, k)=>{
					let answer = `${k+1}/${String.fromCharCode(k+65)}`
					//return <div className={this.state.selectedAnswer === answer ? 'activeAnswer' : ''} onClick={()=>this.onClickHandle(answer)}>{answer}</div>
					return <AnswerCell 
						key={"mc-ans-"+k} 
						answer={answer} 
						disabled={this.props.disabled}
						onClickHandle={this.onClickHandle} 
						isActive={this.state.selectedAnswer === answer} 
					 />
				})
			}
		</div>
	}
}
class LikertTask extends MCTask{
	render(){
		console.log(this.props.answer)
		return <div 
			style={{
				width: '80%',
				margin: 'auto'
			}}
		>
			<div 
				style={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<div style={{
					flex: 0,
					'whiteSpace': 'nowrap'
				}}>{this.props.lowText}</div>
				<div style={{
					flex: 0,
					'whiteSpace': 'nowrap'
				}}>{this.props.highText}</div>
			</div>
			<Slider 
				defaultValue={this.props.answer.length > 0 ? 
					parseInt(this.props.answer) : 
					parseInt((this.props.numOfAnswer+1)/2)
				} 
				step={1} 
				marks 
				disabled={this.props.disabled}
				valueLabelDisplay="auto"
				min={1} 
				max={this.props.numOfAnswer}
				onChange={(_, value)=>this.value=value} />
		</div>
	}
}
let TextTask = React.forwardRef((props, ref) => {
	return (
	<Form.Group controlId="textQuestion">
		<Form.Label>Submission</Form.Label>
		<Form.Control as="textarea" row={3} ref={ref} onChange={(e) => {
			if(e.target.value.length > 0){
				props.onSelected()
			}
			else{
				props.onCanceled()
			}
		}}/>
	</Form.Group>);
})
class QuestionTask extends React.Component{
	log(msg){
		utilLog('QuestionTask:', msg)
	}
	constructor(props){
		super(props)
		this.log('constructor')
		this.questionTaskRef = React.createRef(null)
	}
	getAnswer(){
		return this.questionTaskRef.current.value
	}
	render(){
		this.log('this.props.task.tips', this.props.task.tips)
		let disabled = this.props.noChance || this.props.status === 'submitted'
		return (
			<React.Fragment>
				<div className="task-html" dangerouslySetInnerHTML={{__html: this.props.task.questiontext}}></div>
				{
					this.props.submittedAnswer.length > 0 && 
					this.props.task.qtype?.toLowerCase() !== 'likert' && 
					<div>
						<div>You have answered: </div>
						<div style={{
					    maxHeight: '30vh',
					    overflowY: 'auto',
					    margin: '10px 0',
					    border: '1px solid lightgray',
					    whiteSpace: 'pre-wrap',
					    overflowWrap: 'break-word',
					    wordBreak: 'break-word',
						}}>{this.props.submittedAnswer}</div>
					</div>
				}
				{
					this.props.task.qtype?.toLowerCase() === 'mc' && 
					<MCTask 
						ref={this.questionTaskRef} 
						disabled={disabled}
						answer={this.props.submittedAnswer}
						numOfAnswer={this.props.task.num_of_answer} 
						onSelected={this.props.onSelected}
						onCanceled={this.props.onCanceled}
					/>
				}
				{
					this.props.task.qtype?.toLowerCase() === 'likert' && 
					<LikertTask 
						ref={this.questionTaskRef} 
						disabled={disabled}
						answer={this.props.submittedAnswer.length > 0 ? this.props.submittedAnswer : '3'}
						numOfAnswer={this.props.task.num_of_answer} 
						lowText={this.props.task.lowtext} 
						highText={this.props.task.hightext} 
						// onSelected={this.props.onSelected}
						// onCanceled={this.props.onCanceled}
					/>
				}
				{
					this.props.task.qtype?.toLowerCase() === 'text' && 
					!disabled && 
					<TextTask 
						ref={this.questionTaskRef} 
						onSelected={this.props.onSelected}
						onCanceled={this.props.onCanceled}
					/>
				}
				<Alert key="question_error" variant="danger" show={this.props.errorMsg?.length > 0}>
					{/*You have no chance to answer this question*/}
					{this.props.errorMsg}
				</Alert>
				<Alert key="question_noChance" variant="danger" show={this.props.noChance && this.props.status === 'init'}>
					You have already answered this question.
				</Alert>
				<Alert key="question_correct" variant="success" show={this.props.isCorrect}>
					You are correct. You got {this.props.score} point(s).
				</Alert>
				<Alert key="question_wrong_try_again" variant="danger" show={this.props.tryAgain}>
					You are wrong. Please try again.
				</Alert>
				<Alert key="question_wrong" variant="danger" show={this.props.isWrongOnly}>
					You are wrong.
				</Alert>
				<Alert key="feedback" variant="info" show={this.props.feedback?.trim().length > 0}>
					Feedback: {this.props.feedback}
				</Alert>
				<Alert key="correctAns" variant="info" show={this.props.correctAns?.trim().length > 0}>
					Correct Answer: {this.props.correctAns}
				</Alert>
			</React.Fragment>
		);
	}
}
QuestionTask.defaultProps = {
	score: '',
	isCorrect: false,
	tryAgain: false,
	isWrongOnly: false,
	noChance: false
}
function ContentTask({task}){
	return <div className="task-html" dangerouslySetInnerHTML={{__html: task.content}}></div>
}
let DownloadButton = function({task, src, children, filename}){
	utilLog(children)
	if(!children){
		children = 'Download'
	}
	if(!filename){
		filename = 'file_'+parseInt(Math.random()*1000)+(new Date()).getTime()
	}
	let download = function(src){
		sendRequest(src).then(response=>{
			utilLog(response)
			let [type, ext] = response.headers.get('content-type').split('/')
			if(false && ['audio', 'video', 'image'].indexOf(type)>-1){
				type = type+'/*'
			}
			else{
				type = '*/*'
			}
			utilLog(ext)
			response.arrayBuffer().then(buffer=>{
				if(task && task.special_type &&
					['audio', 'video', 'image'].indexOf(task.special_type) === -1){
					type = '*/*';
				}
				// let aTag = window.document.createElement('a');
				// aTag.target = '_blank';
					// type = ['audio', 'video', 'image'].indexOf(task.special_type) > -1 ? task.special_type+'/*' : '*/*';
				// let url = URL.createObjectURL(new Blob([buffer]), {type: type})
				aTag.current.href = src
				// aTag.current.download = `${filename}.${ext}`
				aTag.current.click()
			})
		})
	}
	let aTag=React.useRef(null)
	return <div>
		<Button
		variant="success"
		onClick={_=>download(src)}>{children}</Button>
		<a target="_blank" href="#" ref={aTag}></a>
		</div>
}
function UploadTask({task}){
	if(task.special_type === 'audio') return (<audio style={{maxWidth: '100%'}} src={task.upload_file} controls />)
	if(task.special_type === 'video') return (<video style={{maxWidth: '100%'}} src={task.upload_file} controls />)
	if(task.special_type === 'image') return (<img style={{maxWidth: '100%'}} src={task.upload_file} />)
	return <DownloadButton task={task} src={task.upload_file} />
	// return <div></div>
	//if(task.special_type === 'none') return <DownloadButton task={task} src={task.upload_file} />
	//return <div></div>
}
// need to check
let ImageqTask = React.forwardRef(({task, noChance, answer_files, onError, onPass, status}, ref) => {
	let [label, setLabel] = React.useState("")
	let [error, setError] = React.useState("")
	let download_files = !noChance ? task.answer_files : answer_files
	let log = (...msg) => utilLog.apply(null, ['ImageqTask'].concat(msg))
	return <div>
		<div className="task-html" dangerouslySetInnerHTML={{__html: task.content}}></div>
		{download_files?.length>0 && <div>
			<div>File(s) Uploaded Successfully!</div>
			<div style={{
				display: 'flex',
				flexWrap: 'wrap',
				margin: '10px auto'
			}}>
				{download_files.map((file, k) => {
					return (
						<div key={k} style={{
							padding: '0 10px'
						}}>
							<DownloadButton src={file.url} filename={file.name} >
								<GetAppIcon />{file.name}
							</DownloadButton>
						</div>
					)
				})}
			</div>
		</div>}
		{!noChance && <div style={{
			margin: '10px auto'
		}}>
			<Alert key="upload-file-oversize" variant="danger" show={error.length > 0}>
				{error}
			</Alert>
			<Form>
				<Form.File 
					id="custom-file"
					label={label.length > 0 ? label : 'Please upload files'}
					name="upload_file"
					custom
					multiple
					onChange={(e)=>{
						let files = e.target.files;
						// if no file then return
						if(files.length === 0){
							// reset label
							setLabel('')
							onError()
							return;
						}
						// check total filesize
						let total_filesize = Array.from(files).reduce((acc, files)=>acc + files.size, 0)
						log('total_filesize', total_filesize)
						if(total_filesize > 256000000){
							// if total filesize is larger then 256MB, throw error
							setError('file size is larger than 256MB')
							onError()
						}
						else{
							setError('')
							onPass()
						}
						setLabel(Array.from(files).map(f=>f.name).join(', '))
					}}
					ref={ref}
				/>
			</Form>
		</div>}
		<Alert key="question_noChance" variant="danger" show={noChance && status === 'init'}>
			You have no chance to answer this question
		</Alert>
	</div>
})

class TaskFragment extends React.Component{
	log(...msg){
		msg.unshift('TaskFragment:')
		utilLog.apply(null, msg)
	}
	constructor(props){
		super(props)
		this.log('constructor')
		this.state = {
			task: this.props.task,
			submittedAnswer: '',
			errorMsg: '',
			score: '',
			isCorrect: false,
			tryAgain: false,
			isWrongOnly: false,
			timeConsume: '',
			tripTimeConsume: '',
			answerable: true,
			submitable: false,
			answer_files: [],
			// showFeedback: false,
			feedback: '',
			correctAns: '',
			score_level: '',
			time_level: '',
			final_score: NaN,
			status: 'init'
		}
		this.taskRef = React.createRef(null)
	}
	componentDidMount(){
		this.log('componentDidMount')
		this.updateTaskStatus()
	}
	updateTaskStatus(){
		let defaultState = {
			submittedAnswer: '',
			errorMsg: '',
			score: '',
			isCorrect: false,
			tryAgain: false,
			isWrongOnly: false,
			timeConsume: '',
			tripTimeConsume: '',
			answerable: true,
			submitable: false,
			answer_files: [],
			// showFeedback: false,
			feedback: '',
			correctAns: '',
			score_level: '',
			time_level: '',
			final_score: NaN,
			status: 'init'
		}
		sendRequest(`student/trip/points/${this.props.task.point_code}/tasks/${this.props.task.code}`).then(r => {
			let score_level = '',
			time_level = '',
			final_score=NaN,
			tripTimeConsume=''
			this.log(r)
			if(r.trip_time_consume !== undefined){
				// print time string
				let temArray=[]
				if(r.trip_time_consume.year > 0){
					if(r.trip_time_consume.year > 1){
						temArray.push(r.trip_time_consume.year+' years')
					}
					else{
						temArray.push(r.trip_time_consume.year+' year')
					}
				}
				if(r.trip_time_consume.month > 0){
					if(r.trip_time_consume.month > 1){
						temArray.push(r.trip_time_consume.month+' monthes')
					}
					else{
						temArray.push(r.trip_time_consume.month+' month')
					}
				}
				if(r.trip_time_consume.day > 0){
					if(r.trip_time_consume.day > 1){
						temArray.push(r.trip_time_consume.day+' days')
					}
					else{
						temArray.push(r.trip_time_consume.day+' day')
					}
				}
				if(r.trip_time_consume.hour > 0){
					if(r.trip_time_consume.hour > 1){
						temArray.push(r.trip_time_consume.hour+' hours')
					}
					else{
						temArray.push(r.trip_time_consume.hour+' hour')
					}
				}
				if(r.trip_time_consume.minute > 0){
					if(r.trip_time_consume.minute > 1){
						temArray.push(r.trip_time_consume.minute+' minutes')
					}
					else{
						temArray.push(r.trip_time_consume.minute+' minute')
					}
				}
				if(r.trip_time_consume.second > 0){
					if(r.trip_time_consume.second > 1){
						temArray.push(r.trip_time_consume.second+' seconds')
					}
					else{
						temArray.push(r.trip_time_consume.second+' second')
					}
				}
				tripTimeConsume = temArray.pop()
				if(temArray.length > 0){
					tripTimeConsume = temArray.join(', ') + ' and ' + tripTimeConsume
				}
			}
			if(r.final_score !== undefined){
				final_score=r.final_score
			}
			if(r.score_percentage !== undefined){
				if(r.score_percentage > 85){
					score_level = 'gold'
				}
				else if(r.score_percentage > 50){
					score_level = 'sliver'
				}
				else{
					score_level = 'bronze'
				}
			}

			if(r.time_percentage !== undefined){
				if(r.time_percentage > 85){
					time_level = 'gold'
				}
				else if(r.time_percentage > 50){
					time_level = 'sliver'
				}
				else{
					time_level = 'bronze'
				}
			}
			let newState = {
				task: r,
				feedback: r.feedback !== undefined ? r.feedback : '',
				correctAns: r.show_corr_ans ? r.correctanswer : '',
				submittedAnswer: r.answer ? r.answer : '',
				answer_files: r.answer_files,
				answerable: r.can_answer,
				submitable: r.can_answer && (r.type === 'question' && r.qtype === 'likert'),
				tripTimeConsume: tripTimeConsume,
				score_level: score_level,
				time_level: time_level,
				final_score: final_score,
				status: 'init'
			}
			newState = Object.assign({}, defaultState, newState)
			this.setState(newState)
		})
	}
	componentDidUpdate(){
		this.log('componentDidUpdate')
	}
	// submit answer to server for task question(mc, likert) and imageq
	async submitTask(){
		let fd = new FormData()
		let answer = ''
		let blob_files = []
		if(this.state.task.type === 'question'){
			// get anwer for question task
			answer = this.taskRef.current.getAnswer()
			fd.append('answer', answer)
		}
		else if(this.state.task.type === 'imageq'){
			// get files
			// blob_files is for download file back
			if(this.taskRef.current.files.length === 0){
				return;
			}
			let files = this.taskRef.current.files
			Array.from(files).forEach(f=>{
				fd.append('submitted_file[]', f)
				blob_files.push({
					url: URL.createObjectURL(f),
					name: `file_${blob_files.length+1}`
				})
			})
		}
		// 
		let is_correct = false, 
		score = '', 
		tryAgain = false, 
		isWrongOnly = false, 
		timeConsume = '', 
		tripTimeConsume = '', 
		score_level = '',
		time_level = '',
		final_score = NaN;
		try{
			// post answer to server
			let result = await sendRequest(`student/trip/points/${this.props.task.point_code}/tasks/${this.props.task.code}/answer`, {
				method: 'post', 
				data: fd
			})
			// 
			if(result.auto_mark){
				is_correct = result.is_correct
				if(result.is_correct){
					// score of this task
					score = result.score
				}
				else{
					if(result.redo){
						tryAgain = true
					}
					else{
						isWrongOnly = true
						if(this.state.task.type === 'question' 
						 && this.state.task.qtype.toLowerCase() === 'mc'){
							this.setState({
								correctAns: result.show_corr_ans ? result.correctanswer : ''
							})
						}
					}
				}
			}
			if(this.state.task.type === 'question'){
				this.setState({submittedAnswer: answer})
				if(result.is_correct || !result.redo){
					this.setState({
						feedback: result.show_feedback ? result.feedback : ''
					})
				}
			}
			let timeStringify = time => {
				let temArray=[]
				if(time.year > 0){
					if(time.year > 1){
						temArray.push(time.year+' years')
					}
					else{
						temArray.push(time.year+' year')
					}
				}
				if(time.month > 0){
					if(time.month > 1){
						temArray.push(time.month+' monthes')
					}
					else{
						temArray.push(time.month+' month')
					}
				}
				if(time.day > 0){
					if(time.day > 1){
						temArray.push(time.day+' days')
					}
					else{
						temArray.push(time.day+' day')
					}
				}
				if(time.hour > 0){
					if(time.hour > 1){
						temArray.push(time.hour+' hours')
					}
					else{
						temArray.push(time.hour+' hour')
					}
				}
				if(time.minute > 0){
					if(time.minute > 1){
						temArray.push(time.minute+' minutes')
					}
					else{
						temArray.push(time.minute+' minute')
					}
				}
				if(time.second > 0){
					if(time.second > 1){
						temArray.push(time.second+' seconds')
					}
					else{
						temArray.push(time.second+' second')
					}
				}
				let timeString = temArray.pop()
				if(temArray.length > 0){
					timeString = temArray.join(', ') + ' and ' + timeString
				}
				return timeString
			}
			// always show time spent
			if(true || result.is_correct || !result.redo){
				// print time string
				timeConsume = timeStringify(result.time_consume)
			}
			if(result.trip_time_consume !== undefined){
				// print time string
				tripTimeConsume = timeStringify(result.trip_time_consume)
			}
			if(result.final_score !== undefined){
				final_score=result.final_score
			}
			// if(result.badge_system.indexOf('score-type') > -1){
			// 	// this.setState({
			// 	// 	'score_level': 
			// 	// })
			// }
			if(result.score_percentage !== undefined){
				if(result.score_percentage > 85){
						score_level = 'gold'
				}
				else if(result.score_percentage > 50){
						score_level = 'sliver'
				}
				else{
						score_level = 'bronze'
				}
			}

			if(result.time_percentage !== undefined){
				if(result.time_percentage > 85){
						time_level = 'gold'
				}
				else if(result.time_percentage > 50){
						time_level = 'sliver'
				}
				else{
						time_level = 'bronze'
				}
			}
		}catch(e){
			console.error(e)
			// this.setState({errorMsg: e.msg})
		}finally{
			this.setState({
				isCorrect: is_correct,
				score: score,
				tryAgain: tryAgain,
				isWrongOnly: isWrongOnly,
				timeConsume: timeConsume,
				tripTimeConsume: tripTimeConsume,
				answerable: false,
				answer_files: blob_files,
				score_level: score_level,
				time_level: time_level,
				final_score: final_score,
				status: 'submitted'
			})
			this.tryUpdatingStatus()
		}
	}
	tryUpdatingStatus(){
		if(this.props.onUpdateStatus){
			this.props.onUpdateStatus(t => {
				this.log('onUpdateStatus: ', t, this.props.task.code)
				return t.parent_order_code === this.props.task.code
			}, child_task => {
				return Object.assign({}, child_task, {pass: true})
			})
			this.props.onUpdateStatus(t => {
				this.log('onUpdateStatus: ', t, this.props.task.code)
				return t.code === this.props.task.code
			}, child_task => {
				return Object.assign({}, child_task, {done: true})
			})
		}
	}
	render(){
		this.log('this.props.task.tips', this.props.task.tips)
		return (
			<div style={{position: 'relative'}}>
				{/*<div>QuestionContent</div>*/}
				{
					this.state.task.name.length > 0 && 
					<div>
						{/*<Typography variant="h6" gutterBottom>Name</Typography>*/}
						<Typography 
							variant="h6" 
							gutterBottom>
							{this.state.task.name}
						</Typography>
					</div>
				}
				{
					this.state.task.description.length > 0 && 
					<div>
						<Typography variant="h6" gutterBottom>Description</Typography>
						<div 
							className="task-html" 
							dangerouslySetInnerHTML={{__html: this.state.task.description}}></div>
					</div>
				}
				{
					this.state.task.tips?.length > 0 && 
					<Tipbtn 
						tips={this.state.task.tips}
					/>
				}
				<div>
					{
						this.state.task.type === 'question' && 
						<QuestionTask 
							ref={this.taskRef}
							task={this.state.task} 
							submittedAnswer={this.state.submittedAnswer}
							errorMsg={this.state.errorMsg}
							score={this.state.score}
							isCorrect={this.state.isCorrect}
							tryAgain={this.state.tryAgain}
							isWrongOnly={this.state.isWrongOnly}
							noChance={!this.state.answerable}
							feedback={this.state.feedback}
							correctAns={this.state.correctAns}
							status={this.state.status}
							onSelected={_=>this.setState({
								submitable: true
							})}
							onCanceled={_=>this.setState({
								submitable: false
							})}
						/>
					}
					{
						this.state.task.type === 'content' && 
						<ContentTask 
							task={this.state.task}
						/>
					}
					{
						this.state.task.type === 'upload' && 
						<UploadTask 
							task={this.state.task}
						/>
					}
					{
						this.state.task.type === 'imageq' && 
						<ImageqTask 
							ref={this.taskRef}
							task={this.state.task}
							noChance={!this.state.answerable}
							answer_files={this.state.answer_files}
							status={this.state.status}
							onError={_=>this.setState({
								submitable: false
							})}
							onPass={_=>this.setState({
								submitable: true
							})}
						/>
					}
				</div>
				{
					['question', 'imageq'].indexOf(this.state.task.type) > -1 && 
					<Alert show={this.state.timeConsume.length > 0} variant="success">
						You have used {this.state.timeConsume} to finish this task.
					</Alert>
				}
				{/*<Alert show={this.state.tripTimeConsume.length > 0} variant="success">
					You have used {this.state.tripTimeConsume} to finish this trip.
				</Alert>
				<Alert show={!isNaN(this.state.final_score)} variant="success">
					You got {this.state.final_score} in this trip.
				</Alert>*/}
				<div style={{
					display: 'flex'
				}}>
				{
					this.state.score_level.length > 0 &&
					<div style={{flex: 1}}>
							<div style={{textAlign: 'center'}}>Score</div>
					{
						this.state.score_level === 'gold' && 
							<img style={{width: '100%'}} src={GoldBadge} />
					}
					{
						this.state.score_level === 'sliver' && 
							<img style={{width: '100%'}} src={SliverBadge} />
					}
					{
						this.state.score_level === 'bronze' && 
							<img style={{width: '100%'}} src={BronzeBadge} />
					}
					</div>
				}
				{
					this.state.time_level.length > 0 &&
					<div style={{flex: 1}}>
							<div style={{textAlign: 'center'}}>Time</div>
					{
						this.state.time_level === 'gold' && 
							<img style={{width: '100%'}} src={GoldBadge} />
					}
					{
						this.state.time_level === 'sliver' && 
							<img style={{width: '100%'}} src={SliverBadge} />
					}
					{
						this.state.time_level === 'bronze' && 
							<img style={{width: '100%'}} src={BronzeBadge} />
					}
					</div>
				}
				</div>
				<div style={{textAlign: 'right'}}>
					{
						this.state.status === 'submitted' && 
						!this.state.isCorrect &&
						<Button
							variant="light"
							onClick={() => this.updateTaskStatus()}
						>
							Retry
						</Button>
					}
					{
						['question', 'imageq'].indexOf(this.state.task.type) > -1 && 
						this.state.status === 'init' && 
						this.state.submitable &&
						<Button 
							variant="success" 
							onClick={() => this.submitTask()}
						>Submit</Button>
					}
					<Button 
						variant="light" 
						onClick={async () => {
							// can send an back question request
							if(['question', 'imageq'].indexOf(this.state.task.type) === -1){
								await sendRequest(`student/trip/points/${this.props.task.point_code}/tasks/${this.props.task.code}/exit`, {
									method: 'post', 
								}).then(r => {
									this.tryUpdatingStatus()
								})
							}
							this.props.onBack();
						}}
					>OK</Button>
				</div>
			</div>
		)
	}
	componentWillUnmount(){
		this.state.answer_files?.forEach(src => {
			URL.revokeObjectURL(src)
		})
	}
}

export default TaskFragment;
